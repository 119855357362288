import Cookies from 'js-cookie';
import React, {useEffect} from 'react';
import './App.scss';
import ClientRegister from './components/client-register';
import { Cardboard, defaultData, server } from './components/client-register/kanban-board/data';
import { APIMETHOD } from './core/constant';
import { axiosInstance, useAxios } from './core/useAxios';
import LoadingService from './shared/components/loader';
import useDidUpdateEffect from './shared/hooks/useDidUpdate';
import { createAuthHeader, defaultBoardData } from './shared/utils';

export const boardContext = React.createContext<any>(null);

function App() {
  // const { get, error} = useAxios();
  const config = server();
  // const defaultData: any = config.getData;
  const [boardData, setBoardData] = React.useState<any>(defaultBoardData);
  const [defaultStage, setDefaultStage] = React.useState(boardData?.defaultStageNo || 5);
  const [accessToken, setAccessToken] = React.useState<string>()
  const [auth, setAuth] = React.useState<boolean>();
  const [isLoader, setIsLoader] = React.useState<boolean>(false);
  
  const [stageList, setStageList] = React.useState<any[]>([]);

  
  // useEffect(() => {
  //   const getData = async() => {
  //     const data: any = await config.getData();
  //     if(data) {
  //       setBoardData(data);
  //       setDefaultStage(data?.defaultStageNo);
  //     }
  //   }
  //   getData();
  // }, []);

  useEffect(() => {
    const getToken = async() => {
      const accessToken = await Cookies.get('token')
      if(accessToken) {
      setAccessToken(accessToken)
      setAuth(true)
      }
    }
    getToken()
  }, [auth])

  useDidUpdateEffect(() => {
    const getData = async() => {
      setIsLoader(true);
      const stage: any = await getStage();
      const client: any = await getClients();
      setIsLoader(false);
      const boardColumns = mapResponse(stage?.data, client?.data);
      boardColumns.sort((p1: any, p2: any) => (p1.order > p2.order) ? 1 : (p1.order < p2.order) ? -1 : 0);
      boardColumns.forEach((column: any) => {
        if (column.id === 42) {
        column.cards.sort((c1: any, c2: any) => {
          const dateA = c1.application_date ? new Date(c1.application_date).getTime() : 0;
          const dateB = c2.application_date ? new Date(c2.application_date).getTime() : 0;
          return dateB - dateA;
        });
      }
      });
      boardData.columns = boardColumns;
      setBoardData(boardData);
      getConfigData();
    }
      if(accessToken || auth) {
        getData();
      };
    
}, [accessToken]);


const getConfigData = async () => {
  const res: any = await _getConfigData();
  if(res) {
    const board_data = {...boardData, ...res.value, ...{kvId: res.id, boardkvId: res.boardkvId}};
    setBoardData(board_data);
    // setDefaultStage(res?.defaultStageNo || defaultStage);
  }
}


  const mapResponse = (stageList: any, clientList: any) => {
     if(stageList?.results?.length > 0 && clientList?.results?.length > -1) {
        return stageList.results?.map((st: any) => {
          st.cards = clientList?.results.filter((cl: any) => st.id === cl.stage);
          return st;
        })
     }
  }

  const getStage = async() => {
    return await axiosInstance.get(APIMETHOD.clients.GET_STAGE, {headers: createAuthHeader()});
  }
  const getClients = async() => {
    return await axiosInstance.get(APIMETHOD.clients.GET_CLIENT, {headers: createAuthHeader()});
  }
 const _getConfigData = async() => {
    setIsLoader(true);
    const res: any = await axiosInstance.get(APIMETHOD.kv.CREATE, {headers: createAuthHeader()});
    setIsLoader(false);
    if(res && res.data) {
      const data: any = res.data?.results?.filter((x: any)=>x.key === 'config_data');
      const boardKVdata: any = res.data?.results?.filter((x: any)=>x.key === 'board_data');
      if(boardKVdata && boardKVdata?.length > 0) {
        data[0].boardkvId = boardKVdata[0]?.id;
      }
      return data[0] || {};
    }
  }
  const value: any = {
    val() {
      return {
        boardData,
        setBoardData,
        defaultStage,
        setDefaultStage,
        config,
        auth,
        setAuth,
        setIsLoader
      }
    }
  }
  return (
    <div className="">
      <boardContext.Provider value={value.val()}>
        {isLoader && <LoadingService open={isLoader}/>}
        <ClientRegister />
      </boardContext.Provider>
    </div>
  );
}

export default App;
